import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../v2/Modal';
import Button from '../legacy/Construction/Button';
import Grid from '../Grid';
import GridColumn from '../GridColumn';
import wrapEventHandler, { ARG_ORDER } from '../utils/wrapEventHandler';

class DialogueModal extends React.PureComponent {
  render() {
    const {
      children,
      negativeText,
      positiveText,
      header,
      isOpen,
      onClose,
    } = this.props;
    return (
      <Modal
        roundedCorners={false}
        headerDivider={false}
        header={header}
        size="small"
        isOpen={isOpen}
      >
        <div style={{ marginBottom: '2rem' }}>
          {children}
        </div>
        <Grid>
          <GridColumn sm="6">
            <Button
              type="secondary"
              dataTestid={negativeText}
              onClick={wrapEventHandler(onClose, [false], ARG_ORDER.EVENT_LAST, this)}
              block
            >
              {negativeText}
            </Button>
          </GridColumn>
          <GridColumn sm="6">
            <Button
              type="primary"
              dataTestid={positiveText}
              onClick={wrapEventHandler(onClose, [true], ARG_ORDER.EVENT_LAST, this)}
              block
            >
              {positiveText}
            </Button>
          </GridColumn>
        </Grid>
      </Modal>
    );
  }
}

DialogueModal.propTypes = {
  children: PropTypes.node.isRequired,
  negativeText: PropTypes.string,
  positiveText: PropTypes.string,
  header: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

DialogueModal.defaultProps = {
  negativeText: 'Cancel',
  positiveText: 'Confirm',
  header: '',
  onClose: () => {},
};

export default DialogueModal;
