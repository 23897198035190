import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import {
  FieldGroup as Group,
  FieldLabel as Label,
  FieldTextInput as TextInput,
} from '../..';
import HelpText from '../../legacy/Construction/Field/HelpText/HelpText';
import TextInputWrapper from '../../legacy/Construction/Field/TextInputWrapper/TextInputWrapper';
import Icon from '../../legacy/Construction/Field/Icon/Icon';
import omitProps from '../../HigherOrderComponents/omitProps';

class AbstractInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id || uuid(),
      validation: false,
    };
  }

  validation(event) {
    const {
      internalValidation,
      onValid,
    } = this.props;
    const initialValidation = internalValidation(event);

    if (initialValidation === true || (
      initialValidation && initialValidation.type === 'success'
    )) {
      if (onValid) {
        return onValid(event);
      }
    }
    return initialValidation;
  }

  onBlur = (event) => {
    const {
      optional,
      onBlur,
    } = this.props;
    event.persist();
    // Exit case if optional and empty value
    if (optional && event.target.value === '') {
      if (onBlur) {
        onBlur(event);
      }
      return;
    }

    const validation = this.validation(event);
    this.setState({ validation: validation === true ? {} : validation }, () => {
      if ((validation === true || (validation && validation.type === 'success'))
        && onBlur
      ) {
        onBlur(event);
      }
    });
  };

  onFocus = (event) => {
    const {
      onFocus,
    } = this.props;

    if (onFocus) {
      onFocus(event);
    }
  }

  renderIcon(orientation) {
    const { icon, iconOrientation } = this.props;

    return ((icon.length > 0 && iconOrientation === orientation)
      && (
        <Icon
          icon={icon}
          orientation={orientation}
        />
      )
    );
  }

  render() {
    const {
      id,
      validation: { type: validationType = '', message: validationMessage } = {},
    } = this.state;
    const {
      additionalInfo,
      helpText,
      format,
      formatChar,
      label,
      labelHidden,
      optional,
      iconOrientation,
      applyFocus,
      pattern,
      ...props
    } = this.props;

    return (
      <Group id={id} validation={validationType}>
        <Label
          screenReaderOnly={labelHidden}
          optional={optional}
          help={additionalInfo}
        >
          {label}
        </Label>
        <TextInputWrapper>
          {this.renderIcon('left')}
          <TextInput
            {...props}
            hasIconLeft={iconOrientation === 'left'}
            hasIconRight={iconOrientation === 'right'}
            {...(format ? { mask: format, maskChar: formatChar } : {})}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            applyFocus={applyFocus}
          />
          {this.renderIcon('right')}
        </TextInputWrapper>
        {validationMessage && <HelpText>{validationMessage}</HelpText>}
        {helpText && <HelpText fieldValidation="">{helpText}</HelpText>}
      </Group>
    );
  }
}

AbstractInput.propTypes = {
  additionalInfo: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  format: PropTypes.string,
  formatChar: PropTypes.string,
  id: PropTypes.string,
  internalValidation: PropTypes.func,
  label: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  optional: PropTypes.bool,
  onValid: PropTypes.func,
  type: PropTypes.oneOf(['text', 'email', 'search', 'phone', 'number', 'password']),
  value: PropTypes.string.isRequired,
  iconOrientation: PropTypes.oneOf(['', 'right', 'left']),
  icon: PropTypes.oneOf(['', 'dollar', 'search', 'event']),
  applyFocus: PropTypes.bool,
  pattern: PropTypes.string,
};

AbstractInput.defaultProps = {
  additionalInfo: '',
  disabled: false,
  helpText: '',
  format: undefined,
  formatChar: null,
  id: undefined,
  internalValidation: () => true,
  labelHidden: false,
  onBlur: undefined,
  onFocus: undefined,
  onKeyUp: undefined,
  onValid: undefined,
  optional: false,
  type: 'text',
  iconOrientation: '',
  icon: '',
  applyFocus: false,
  pattern: null,
};

export default omitProps(['className', 'style'])(AbstractInput);
